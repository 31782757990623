@import 'styles/variables';

.root {
  margin-bottom: 16px;
}

.fields {
  display: flex;
}

.side {
  display: flex;
  &:first-child {
    flex: 0.8;
    margin-right: 12px;
  }

  &:last-child {
    flex: 1.2;
  }

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.error {
  display: block;
  margin-top: 5px;
  color: $color-error;
  font-size: 11px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .fields {
    display: block;
  }

  .side {
    width: 100%;

    &:first-child {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .side:last-child {
    flex-wrap: wrap;

    > div {
      min-width: calc((100% / 3) - 24px);

      &:not(:last-child) {
        margin-right: 0;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 12px;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 12px;
      }
    }
  }
}
