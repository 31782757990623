.b-up-down-button {
  display: inline-flex;
  align-items: center;

  span {
    width: 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  &--size--small {
    span {
      margin: 0 4px;
    }

    .b-button {
      width: 18px;
      height: 18px;
      padding: 4px;
      line-height: 8px;
    }
  }

  &--size--medium {
    span {
      margin: 0 8px;
    }

    .b-button {
      width: 24px;
      height: 24px;
      padding: 4px;
      font-size: 18px;
      line-height: 14px;
    }
  }
}
