* {
  box-sizing: border-box;
  scrollbar-color: $color-text-secondary;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    &-thumb {
      background: $color-text-secondary;
    }

    &-track {
      background: transparent;
    }
  }
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  min-width: 320px;
  height: 100%;
  margin: 0;
  background-color: $color-body-background;
  color: $color-text;
  font-family: $font-roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: $color-link;
  text-decoration: none;
}

b {
  font-weight: 500;
}

img {
  border-style: none;
  vertical-align: middle;
}

input,
textarea {
  appearance: none;
  font-family: $font-roboto;

  &::placeholder {
    color: $color-placeholder;
    font-size: $font-roboto;
  }

  &:focus {
    outline: none;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

.Main {
  max-width: 1216px;
  min-height: var(--fvh);
  padding: 0 20px;
  margin: 0 auto;
  margin-top: var(--main-top);

  &__fullWidth {
    max-width: unset;
    padding: 0;
  }
}

.Catalog {
  &_header {
    position: sticky;
    z-index: 5;
    top: var(--main-top);
    padding: 24px 12px 16px;
    margin: -24px -12px 0;
    background-color: $color-body-background;
  }

  &_title {
    font-family: $font-montserrat;
    font-size: 24px;
    font-weight: 600;
  }

  &_sectionTitle {
    margin-bottom: 12px;
    color: $color-title;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .Main {
    min-height: calc(var(--fvh) - 48px);
  }
}

@media screen and (max-width: 640px) {
  .Catalog_header {
    padding: 16px 12px 12px;
    margin: -16px -12px 0;
  }

  .Catalog_title {
    font-size: 18px;
  }

  .Catalog_sectionTitle {
    margin-bottom: 8px;
    font-size: 14px;
  }
}
