@import 'styles/variables';

.form {
  & > div {
    margin-bottom: 16px;
  }

  & > p {
    margin-bottom: 16px;
    color: $color-label;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
  }

  & > button {
    display: block;
    margin: 0 auto;
  }
}
