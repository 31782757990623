@import 'styles/variables';

.root {
  position: fixed;
  z-index: 10;
  top: 0;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  padding: 0 14px;
  background-color: $color-body-background;

  button {
    margin-left: auto;
  }
}

.icon {
  margin-right: 16px;
  color: $color-icon;
  font-size: 12px;
}

.title {
  margin-right: 16px;

  span {
    display: block;
    color: $color-title;

    &:first-child {
      font-size: 11px;
    }

    &:last-child {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
