.form {
  padding: 0 4px 8px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 160px;
    margin-bottom: 16px;
  }
}

.rating {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.textField {
  margin-bottom: 24px;

  label {
    display: block;
    text-align: left;
  }
}
