@import 'styles/variables';

.b-button {
  border-radius: 4px;
  cursor: pointer;
  font-family: $font-montserrat;
  font-weight: 500;
  line-height: 18px;

  & > svg {
    margin: 0 8px;
  }

  &:focus {
    outline: none;
  }

  &--variant--contained {
    border: 1px solid $color-primary;
    background-color: $color-primary;
    color: $color-white;

    &:hover {
      background-color: $color-primary--hover;
    }

    &:active {
      background-color: $color-primary--active;
    }

    &.b-button--color--error {
      border: 1px solid $color-error;
      background-color: $color-error;
    }
  }

  &--variant--outlined {
    border: 1px solid $color-primary;
    background-color: transparent;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }

    &.b-button--color--default {
      border: 1px solid $color-text-secondary;
      color: $color-text-secondary;

      &:hover {
        background-color: $color-text-secondary;
        color: $color-white;
      }
    }
  }

  &--variant--text {
    border: 1px solid transparent;
    background-color: transparent;
    color: $color-primary;

    &:hover {
      color: $color-primary--hover;
    }

    &:active {
      color: $color-primary--active;
    }

    &.b-button--color--default {
      color: $color-text;
    }
  }

  &--size--small {
    padding: 6px 16px;
  }

  &--size--medium {
    padding: 9px 20px;
    font-size: 14px;
  }

  &--size--large {
    padding: 13px 30px;
    font-size: 18px;
  }

  &:disabled {
    border: 1px solid $color-text-secondary;
    background-color: $color-text-secondary;
  }
}
