@import 'styles/variables';

.root {
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(7, 9, 27, 0.1);
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;

  img {
    width: 24px;
    margin-right: 16px;
  }

  p {
    flex-grow: 1;
    margin-right: 16px;
    color: $color-title;
    font-family: $font-montserrat;
    font-weight: 600;
  }
}

.no-products {
  padding: 0 20px 20px;
  text-align: center;

  img {
    margin: 25px;
  }

  &__title {
    margin-bottom: 8px;
    color: $color-title;
    font-size: 14px;
    font-weight: 500;
  }

  &__description {
    color: $color-text-secondary;
    font-size: 12px;

    a {
      color: $color-primary;
    }
  }
}

.content {
  max-height: 350px;
  padding: 0 16px;
  margin-bottom: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.product {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid $color-divider;

  &__image {
    display: flex;
    flex-basis: 50px;
    flex-grow: 0;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    img {
      object-fit: contain;
    }
  }

  &__info {
    flex-grow: 1;
    margin-right: 16px;
  }

  &Icon {
    padding: 2px;
    background-color: $color-primary;
    border-radius: 4px;
    color: $color-white;
    font-size: 24px;
  }

  &__title {
    // iOS fix
    &:not(:last-of-type) {
      max-width: 105px;
    }

    &:last-of-type {
      max-width: 169px;
    }

    position: relative;

    // display: box;
    display: flex;
    overflow: hidden;
    max-height: 42px;
    -webkit-box-orient: vertical;
    font-size: 12px;
    -webkit-line-clamp: 3;
    line-height: 14px;

    // space
    overflow-wrap: anywhere;
  }

  &__weight {
    color: $color-text-secondary;
    font-size: 10px;
  }

  &__price {
    display: flex;
    align-items: flex-end;

    &-old {
      margin-right: 2px;
      color: $color-text-secondary;
      font-size: 12px;
      text-decoration: line-through;
    }

    &-current {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.sum {
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  span {
    color: $color-primary;
  }
}

.error {
  display: block;
  margin-top: 5px;
  color: $color-error;
  font-size: 11px;
  text-align: center;
}

.footer {
  padding: 0 24px 24px;

  a {
    display: block;
  }

  :global {
    .b-button {
      display: block;
      width: 100%;
    }
  }
}

@media screen and (max-height: 640px) {
  .content {
    max-height: calc(var(--fvh) - 298px);
  }
}

@media screen and (max-width: 640px) {
  // try to fix bug in iOS
  .content {
    max-height: calc(var(--fvh) - 298px);
  }
}
