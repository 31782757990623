@import 'styles/variables';

.root {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  &:not(:checked) ~ .slider::after {
    background-color: $color-icon;
    transform: translateX(0);
  }
}

.slider {
  &Small {
    width: 34px;
    height: 18px;
    border: 1px solid $color-border;
    border-radius: 10px;

    &::after {
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      transform: translateX(16px);
    }
  }

  &Large {
    width: 50px;
    height: 26px;
    border: 1px solid $color-border;
    border-radius: 15px;
    line-height: 24px;

    &::after {
      top: 3px;
      left: 3px;
      width: 20px;
      height: 20px;
      transform: translateX(24px);
    }
  }

  &::after {
    position: absolute;
    background-color: $color-green;
    border-radius: 50%;
    content: '';
    transition: 0.4s;
  }
}
