@import 'react-phone-input-2/lib/material.css';

.react-tel-input {
  .special-label {
    display: none;
  }

  .form-control {
    width: 100%;
    padding: 11px 11px 11px 52px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    color: $color-text;
    font-size: 14px;
    line-height: 16px;

    &:disabled {
      background-color: #f1f1f1;
      cursor: auto;
    }

    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
