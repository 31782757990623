@import 'styles/variables';

.overlay {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
}

.root {
  display: flex;
  width: 420px;
  max-height: calc(100% - 32px);
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px;
  margin: 16px;
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &FullScreen {
    width: 100% !important;
    height: 100% !important;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
  }
}

@media (max-width: 420px) {
  .root {
    max-height: calc(100% - 24px);
    margin: 12px;

    &FullScreen {
      max-height: 100%;
      margin: 0;
    }
  }
}
