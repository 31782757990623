@import './variables';

$rt-toast-width: 305px;
$rt-color-default: $color-primary;
$rt-color-success: $color-success;
$rt-color-error: $color-error;
$rt-font-family: $font-roboto;

@import 'react-toastify/scss/main';

.Toastify__toast {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: $color-text;
  font-size: 14px;

  img {
    margin-right: 8px;
  }

  &--default {
    border-top: 8px solid $rt-color-default;
  }

  &--success {
    border-top: 8px solid $rt-color-success;
  }

  &--error {
    border-top: 8px solid $rt-color-error;
  }
}

@media screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
  }
}
