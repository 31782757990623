@import 'styles/variables';

.root {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  color: inherit;
  fill: currentColor;
  font-size: inherit;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  vertical-align: middle;
}

.colorDefault {
  color: $color-icon;
}

.colorWhite {
  color: $color-white;
}

.colorText {
  color: $color-text;
}

.colorPrimary {
  color: $color-primary;
}

.isHoverable:hover {
  color: $color-primary--hover !important;
  cursor: pointer;
}
