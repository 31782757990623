@import 'styles/variables';

.trigger {
  position: relative;
  padding: 8px 12px 8px 20px;
  border: 2px solid $color-primary;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  user-select: none;
}

.icon {
  position: absolute;
  top: 5px;
  left: -13px;
  padding: 2px;
  background-color: $color-primary;
  border-radius: 4px;
  color: $color-white;
  font-size: 24px;
}

.popper {
  width: 265px;
  padding: 8px 12px;

  &Header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & > img {
      margin-right: 12px;
    }

    & > svg {
      margin-left: auto;
      font-size: 14px;
    }
  }

  &Title {
    color: $color-title;
    font-size: 14px;
    font-weight: 500;
  }

  &Text {
    color: $color-label;
    font-size: 12px;

    span {
      color: $color-success;
    }
  }
}
