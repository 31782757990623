@import 'styles/variables';

.root {
  background-color: #f1f3f8;
}

.top {
  background-color: #efedf5;

  .content {
    height: 58px;
    padding: 15px 20px;
  }
}

.content {
  display: flex;
  max-width: 1216px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 20px;
  margin: 0 auto;

  nav > a {
    margin-right: 16px;
    color: $color-title;
    text-decoration: none;
  }
}

.scrollTop {
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin-left: auto;
  cursor: pointer;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }
}

.title {
  margin-bottom: 20px;
  color: $color-title;
  font-weight: 500;
}

.links {
  & > a {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.divider {
  max-width: 1216px;
  height: 1px;
  margin: 0 auto;
  background-color: $color-divider;
}

.contacts {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px 16px;
  white-space: nowrap;

  .phones {
    display: flex;
    align-items: center;
    gap: 8px 16px;

    & > a {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }

  p,
  a {
    display: block;
    color: $color-text;
    text-decoration: none;
  }
}

.copyright {
  max-width: 620px;
  margin-right: 16px;
  color: #a3a6b9;
  font-size: 12px;
  line-height: 1.75;
}

.paymentMethods {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1240px) {
  .content {
    nav {
      width: 100%;
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    padding-bottom: 48px;
  }

  .content {
    flex-direction: column;
    align-items: stretch;

    nav {
      margin-bottom: 24px;

      a {
        display: block;
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }

  .title {
    margin-bottom: 8px;
  }

  .links {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    img {
      max-width: 120px;
    }
  }

  .contacts {
    flex-direction: column;
    align-items: stretch;

    .phones {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .copyright {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
