@import 'styles/variables';

.root {
  flex: 1;
  align-self: start;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 8px 0;
  }
}

.title {
  margin-right: 18px;
  font-size: 18px;
  font-weight: 500;

  span {
    margin-left: 4px;
    color: $color-text-secondary;
  }
}

.review {
  display: flex;
  align-items: flex-start;
  padding: 24px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-divider;
  }

  &__avatar {
    width: 45px;
    height: 45px;
    margin-right: 12px;
    border-radius: 25px;
    object-fit: cover;
  }

  &__body {
    margin-top: 4px;
  }

  &__title {
    margin-top: 2px;
  }

  &__author {
    margin-right: 4px;
    font-weight: 500;
  }

  &__date {
    color: $color-text-secondary;
    font-size: 12px;
    font-weight: 400;
  }

  &__comment {
    margin-top: 8px;
    font-size: 12px;
  }
}

.no-reviews {
  max-width: 525px;
  margin: 50px auto 0;
  text-align: center;

  img {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
    color: $color-title;
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 600;
  }

  &__description {
    color: $color-text-secondary;
  }
}

@media screen and (max-width: 640px) {
  .header {
    flex-wrap: wrap;
  }
}
