@import 'styles/variables';

.image {
  display: block;
  max-width: 100%;
  margin: 0 auto 16px;
}

.title {
  margin-bottom: 8px;
  color: $color-text;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  white-space: pre-line;
}

.content {
  padding: 20px;
  color: $color-label;
  font-size: 14px;
  text-align: center;

  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    span {
      color: $color-green;
    }
  }
}
