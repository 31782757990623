@import 'styles/variables';

.icon {
  display: none;
}

.popper {
  width: 250px;
  padding: 12px;
}

.links {
  display: flex;
  flex-wrap: wrap;

  a {
    margin-right: 12px;
    color: $color-text;
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: $color-divider;
}

.user {
  display: flex;
  height: 46px;
  align-items: center;

  &Logged:hover {
    background-color: $color-body-background;
    border-radius: 4px;
    cursor: pointer;
  }

  &Avatar {
    border-radius: 50%;
  }

  &Info {
    margin-left: 8px;
  }

  &Name {
    display: block;
    overflow: hidden;
    max-width: 142px;
    color: $color-title;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Phone {
    color: $color-text-secondary;
    font-size: 12px;
  }

  & > svg {
    margin-left: auto;
  }
}

.darkstore {
  &Name {
    display: block;
    color: $color-title;
    font-size: 14px;
    font-weight: 500;
  }

  &Worktime {
    color: $color-text-secondary;
    font-size: 12px;
  }

  &Contacts {
    margin-top: 8px;

    a {
      display: block;
      color: $color-text;
      font-size: 12px;

      &:last-child {
        margin-top: 8px;
      }

      img {
        margin-right: 4px;
      }
    }
  }
}

.socials a {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .icon {
    display: block;
  }
}
