.root {
  position: relative;
  width: 108px;
  height: 108px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }

  label {
    position: absolute;
    right: -8px;
    bottom: 8px;
    cursor: pointer;

    input {
      position: absolute;
      z-index: -1;
      overflow: hidden;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
    }
  }
}
