@import 'styles/variables';

.image {
  display: block;
  max-width: 100%;
  margin: 0 auto 16px;
}

.title {
  margin-bottom: 8px;
  color: $color-text;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.content {
  padding: 0 20px 16px;
  color: $color-label;
  font-size: 14px;
  text-align: center;

  p {
    margin-bottom: 24px;
  }
}
