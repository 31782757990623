@import 'styles/variables';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  &:checked ~ .checkmark::after {
    display: block;
  }

  &:disabled ~ .checkmark::after {
    background: #ffc794;
  }

  &:disabled ~ .checkmark:hover {
    background-color: transparent;
  }
}

.checkmark {
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 1px solid $color-border;
  margin: 0 15px 0 0;
  border-radius: 2px;

  &:hover {
    background-color: #ccc;
  }

  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    display: none;
    width: 10px;
    height: 10px;
    background: linear-gradient(0deg, $color-primary, $color-primary),
      linear-gradient(0deg, $color-primary, $color-primary), $color-primary;
    border-radius: 2px;
    content: '';
  }
}

.label {
  font-size: 14px;
  line-height: 16px;
}
