@import 'styles/variables';

.label {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: $font-sans;
  font-size: 14px;
}

.button {
  color: $color-primary;
  cursor: pointer;
  font-family: $font-montserrat;
  font-size: 12px;
  font-weight: 500;

  input {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
  }
}

.files {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    padding: 6px 20px 6px 10px;
    border: 1px solid #d2d2db;
    margin: 4px;
    border-radius: 4px;
    font-size: 12px;

    & > img {
      margin-right: 6px;
    }

    & > svg {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

.drop {
  padding: 20px;
  border: 2px dashed #d2d2db;
  background-color: $color-white;
  border-radius: 4px;
  outline: none;
  text-align: center;

  img {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 0;
    color: $color-label;
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
  }
}
