@import 'styles/variables';

.root {
  padding: 0;
  background: #e86b19;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.skipButton {
  color: $color-white;
  cursor: pointer;
  font-size: 14px;
}

.slide {
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(var(--fvh) - 140px);
  object-fit: cover;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.button {
  background-color: #fff;
  color: $color-primary;

  &:hover,
  &:active {
    background-color: $color-body-background;
  }
}

@media screen and (max-height: 630px) {
  .root {
    max-width: 280px;
  }
}
