@import 'styles/variables';

.root {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 13px 10px;
  border: none;
  background-color: #656a81;
  color: #d8d9e4;
  cursor: pointer;
  outline: none;

  svg {
    margin-right: 10px;
  }

  &:hover {
    border-color: $color-text;
    background: $color-text;
    color: $color-primary;

    svg {
      color: $color-primary;
    }
  }
}
