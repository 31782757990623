/* stylelint-disable scss/dollar-variable-pattern */
// COLORS
$color-white: #fff;
$color-black: #000;
$color-body-background: #f9fafc;
$color-primary: var(--color-primary);
$color-primary--hover: var(--color-primary--hover);
$color-primary--active: var(--color-primary--active);
$color-primary-filter: var(--color-primary-filter);
$color-error: #f45140;
$color-success: #76c643;
$color-ready: #b4e44d;
$color-waiting: #f8e750;
$color-preparing: #65e3eb;
$color-text: #54586c;
$color-text-secondary: #a4a4b7;
$color-title: #3a405e;
$color-placeholder: #acafbf;
$color-link: #4a97d2;
$color-icon: #bcbcd0;
$color-border: #cacdd9;
$color-divider: #edeff7;
$color-label: #8989a0;
$color-green: #66af37;
$color-discount: #a2c838;
$color-red: #f00;

// FONTS
$font-base: -apple-system, blinkmacsystemfont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-roboto: 'Roboto', $font-base;
$font-montserrat: 'Montserrat', $font-base;
$font-sans: 'Source Sans Pro', $font-base;
