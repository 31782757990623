@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  align-items: flex-start;
  justify-content: space-between;

  &Name {
    font-size: 30px;
    font-weight: 500;
  }

  &Weight {
    margin-left: 8px;
    color: $color-text-secondary;
    font-size: 18px;
    font-weight: 400;
  }

  &CloseIcon {
    margin-top: 4px;
  }
}

.image {
  position: relative;
  flex: 1;
  margin-right: 40px;

  .advIcon {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 300px;
    margin: 0 auto;
    aspect-ratio: 1;
    object-fit: contain;
  }
}

.info {
  flex: 1;

  & > p {
    overflow: hidden;
    max-height: 100px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  b {
    margin-right: 8px;
  }

  &Container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .price {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &Old {
        margin-right: 8px;
        color: $color-text-secondary;
        font-size: 14px;
        text-decoration: line-through;
      }

      &Current {
        margin-right: 10px;
        font-size: 20px;
        font-weight: 500;
      }

      &Discount {
        padding: 3px 6px;
        background-color: $color-discount;
        border-radius: 4px;
        color: $color-white;
        font-size: 14px;
        text-decoration: none;
      }
    }

    .buttons {
      & > * {
        margin-right: 12px;
      }
    }

    .quants {
      margin-top: 12px;
    }
  }
}

.description {
  overflow: hidden;
  max-height: 80px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
}

.actions {
  display: block;
  margin: 0 -16px -16px;
  background-color: $color-primary;
  border-radius: 0 0 8px 8px;

  :global {
    .b-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  .title {
    display: flex;
    height: 48px;

    &Name {
      // display: box;
      display: flex;
      overflow: hidden;
      max-width: calc(100vw - 120px);
      -webkit-box-orient: vertical;
      font-size: 18px;
      -webkit-line-clamp: 2;
      overflow-wrap: anywhere;
    }

    &Weight {
      white-space: nowrap;
    }
  }

  .infoContainer {
    display: block;

    .image {
      margin-right: 0;
      margin-bottom: 24px;

      img {
        max-height: 200px;
      }
    }

    .info > p {
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 420px) {
  .actions {
    border-radius: 0;
  }
}
