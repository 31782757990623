@import 'styles/variables';

.root {
  display: none;
  padding: 6px;
  margin-bottom: 16px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  & > img {
    margin-right: 12px;
  }

  & > span {
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .root {
    display: block;
  }
}

@media screen and (max-width: 380px) {
  .root {
    & > img {
      margin-right: 8px;
    }

    & > span {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 360px) {
  .root > span {
    font-size: 11px;
  }
}
