/* stylelint-disable declaration-block-no-redundant-longhand-properties */
@import 'styles/variables';

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 12px;
    font-size: 20px;
    font-weight: 500;
  }
}

.content {
  padding: 0 16px 16px;
}

.productsList {
  margin-bottom: 18px;
}

.product {
  display: flex;
  align-items: center;
  padding: 20px 15px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-divider;
  }

  &Image {
    display: flex;
    height: 60px;
    flex-basis: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 18px;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &Info {
    // fix for iOS
    max-width: calc(100% - 120px);
    flex-grow: 1;
    margin-right: 18px;
  }

  &Title {
    -webkit-box-orient: vertical;
    font-size: 14px;
    -webkit-line-clamp: 3;
    overflow-wrap: anywhere;
  }

  &Weight {
    color: $color-text-secondary;
    font-size: 12px;
  }

  &Price {
    display: flex;
    align-items: flex-end;

    &Old {
      margin-right: 4px;
      color: $color-text-secondary;
      font-size: 12px;
      text-decoration: line-through;
    }

    &Current {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.early {
  margin-bottom: 8px;
  color: $color-label;
  font-size: 12px;
  text-align: center;
}

.total {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  span {
    color: $color-primary;
  }
}

.image {
  display: block;
  max-width: 100%;
  margin: 0 auto 16px;
}

.text {
  margin-bottom: 16px;
  color: $color-text;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.button {
  display: block;
  margin: 0 auto;
}
