@import 'styles/variables';

.trigger {
  position: relative;
  display: flex;
  min-width: 96px;
  align-items: center;
  padding: 6px 8px;
  background-color: $color-primary;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: $color-primary--hover;
  }

  &Divider {
    width: 1px;
    height: 22px;
    margin: 0 8px;
    background-color: $color-white;
  }

  &Sum {
    display: block;
    margin-bottom: 1px;
    color: $color-white;
    font-size: 12px;
    font-weight: 700;
  }

  &Products {
    display: block;
    color: $color-white;
    font-size: 10px;
    font-weight: 700;
  }

  &Package {
    position: absolute;
    top: -12px;
    right: -6px;
    padding: 2px 4px;
    background-color: $color-success;
    border-radius: 4px;
    color: $color-white;
    font-size: 10px;
    font-weight: 700;
  }
}

.popper {
  width: 275px;
}
