@import 'styles/variables';

.tooltip {
  position: absolute;
  z-index: 10;
  top: 52%;
  left: 50%;
  width: 210px;
  padding: 12px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
  text-align: center;
  transform: translateX(-50%);

  img {
    margin-bottom: 8px;
  }

  &Title {
    margin-bottom: 4px;
    color: $color-title;
    font-size: 12px;
    font-weight: 500;
  }

  &Text {
    font-size: 11px;

    span {
      color: $color-green;
    }
  }

  &Badge {
    padding: 2px 4px;
    background-color: rgba(255, 122, 0, 0.42);
    border-radius: 4px;
    font-size: 11px;

    b {
      font-weight: 600;
    }
  }

  button {
    margin-top: 8px;
  }
}
