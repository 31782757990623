@import 'styles/variables';

.root {
  padding: 24px;
}

.title {
  text-align: center;
}

.caption {
  display: block;
  margin-bottom: 12px;
  color: $color-label;
  font-size: 14px;
  text-align: center;
}

.image {
  display: block;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 12px;
}

.text {
  margin-bottom: 12px;
  font-size: 14px;
  text-align: center;

  &:last-of-type {
    margin-bottom: 24px;
  }
}

.switch {
  display: flex;
  align-items: center;

  span {
    color: $color-label;
    font-size: 12px;
  }

  label {
    margin-right: 8px;
  }

  a {
    color: $color-primary;
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 24px;
  }
}

.button {
  display: block;
  margin: 0 auto;
}
