@import 'styles/variables';

.root {
  border-top: 8px solid $color-primary;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.arrow {
  top: -8px;
  width: 8px;
  height: 8px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: $color-primary;
    content: '';
    transform: translateY(-50%) rotate(45deg);
  }
}
