@import 'styles/variables';

.root {
  position: fixed;
  z-index: 10;
  bottom: 0;
  display: none;
  width: 100%;
  border-top: 1px solid $color-divider;
  background-color: $color-white;
}

.list {
  display: flex;
  max-width: 480px;
  justify-content: space-between;
  padding: 0;
  padding: 5px 20px;
  margin: 0;
  margin: 0 auto;
  list-style: none;

  &Item {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      margin-bottom: 4px;
    }

    p {
      color: $color-text;
      font-size: 12px;
      line-height: 14px;
    }

    &:hover,
    &Active {
      cursor: pointer;

      svg {
        color: $color-primary;
      }

      p {
        color: $color-primary;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .listItem p {
    font-size: 10px;
  }
}
