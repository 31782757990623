@import 'styles/variables';

.header {
  align-items: flex-start;
  margin-bottom: 8px;

  &Title {
    display: block;
    margin-bottom: 4px;
    color: $color-text;
    font-size: 20px;
    font-weight: 500;
  }

  &Subtitle {
    color: $color-label;
    font-size: 14px;
  }
}

.closeIcon {
  margin-top: 4px;
}

.footer {
  justify-content: space-between;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  &Item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 4px;

    &Circle {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
    }

    &Text {
      max-width: 140px;
      font-size: 14px;

      span {
        display: block;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header {
    &Title {
      margin-bottom: 0;
    }

    &Subtitle {
      display: none;
    }
  }

  .footer {
    flex-direction: column;
    align-items: flex-end;

    & > :not(:last-child) {
      margin-right: 0;
    }
  }

  .meta {
    flex-wrap: wrap;

    &Item {
      margin-right: 12px;

      &Circle {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }

      &Text {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
