@import 'styles/variables';

.label {
  display: inline-block;
  margin-bottom: 8px;
  font-family: $font-sans;
  font-size: 14px;
}

.error {
  margin-top: 5px;
  color: $color-error;
  font-size: 11px;
  text-align: center;
}

.checkbox {
  &Root:not(:last-child) {
    margin-bottom: 8px;
  }

  &Checkmark {
    margin-right: 8px;
  }
}
