@import 'styles/variables';

.root {
  position: fixed;
  z-index: 10;
  top: var(--header-top);
  width: 100%;
  border-bottom: 1px solid $color-divider;
  background-color: $color-white;
}

.content {
  display: flex;
  max-width: 1216px;
  height: 75px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 auto;
}

.side {
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
}

.link {
  margin-left: 40px;
  color: $color-text;
  font-family: $font-montserrat;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }
}

@media screen and (max-width: 1240px) {
  .link {
    margin-left: 24px;
  }

  .side > div:not(:last-child) {
    margin-right: 12px;
  }
}

@media screen and (max-width: 1048px) {
  .link {
    display: none;
  }
}

@media screen and (max-width: 864px) {
  // .side > a > img {
  //   width: auto;
  //   height: 48px;
  // }

  .desktopOnly {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .desktopOnly {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .content {
    height: 64px;
  }
}
