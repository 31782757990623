@import 'styles/variables';

.root {
  position: fixed;
  z-index: 80;
  bottom: 16px;
  left: 50%;
  width: 576px;
  padding: 32px;
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(7, 9, 27, 0.16);
  transform: translateX(-50%);
}

.description {
  margin-bottom: 24px;

  &Image {
    margin-bottom: 16px;
  }

  &Text {
    font-size: 14px;

    a {
      color: $color-primary;
    }
  }
}

.footer {
  display: flex;

  & > button:not(:last-child) {
    margin-right: 12px;
  }
}

.list {
  overflow: auto;
  max-height: 50vh;
  padding: 0 32px;
  margin: 0 -32px 24px;
  list-style: none;

  &Item {
    display: flex;
    font-size: 14px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.more {
  margin-bottom: 24px;
  font-size: 12px;

  a {
    color: $color-primary;
  }
}

@media screen and (max-width: 640px) {
  .root {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 480px) {
  .root {
    padding: 24px;
  }

  .list {
    padding: 0 24px;
    margin: 0 -24px 24px;
  }
}
