@import 'styles/variables';

.root {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: $color-primary;
    border-radius: 50%;

    &:nth-child(1) {
      left: 8px;
      animation: ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: ellipsis3 0.6s infinite;
    }
  }
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
