@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.swiper-slide {
  cursor: pointer;
  user-select: none;
}

.swiper-button-next,
.swiper-button-prev {
  z-index: 1;
  width: 42px;
  height: 42px;
  background: $color-white;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgba(7, 9, 27, 0.1);

  &::after {
    display: none;
  }
}

.swiper-button-next {
  right: -20px;
}

.swiper-button-prev {
  left: -20px;
}

.swiper-pagination-bullet {
  border: 1px solid $color-white;
  background-color: transparent;
  opacity: 1;

  &-active {
    background-color: $color-white;
  }
}

@media screen and (max-width: 640px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 36px;
    height: 36px;
  }

  .swiper-button-next {
    right: -16px;
  }

  .swiper-button-prev {
    left: -16px;
  }
}
