.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
}
