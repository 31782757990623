@import 'styles/variables';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  &:checked ~ .checkmark {
    border: 1px solid $color-primary;

    &::after {
      display: block;
    }
  }
}

.checkmark {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid $color-border;
  margin: 0 15px 0 0;
  border-radius: 50%;

  &::after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: none;
    width: 10px;
    height: 10px;
    background-color: $color-primary;
    border-radius: 50%;
    content: '';
  }
}
