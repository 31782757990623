@import 'styles/variables';

.root {
  & > label {
    display: inline-block;
    margin-bottom: 8px;
    font-family: $font-sans;
    font-size: 14px;
  }

  &.variantOutlined {
    padding-top: 4px;
  }
}

.inputContainer {
  position: relative;

  &.variantDefault {
    border: 1px solid $color-border;
    border-radius: 4px;
  }
}

.input {
  width: 100%;
  padding: 11px;
  border: none;
  border-radius: 4px;
  color: $color-text;
  font-size: 14px;
  line-height: 16px;

  &:disabled {
    background-color: #f1f1f1;
  }
}

.spacer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &Start {
    width: 8px;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    border-left: 1px solid $color-border;
    border-radius: 4px 0 0 4px;
  }

  &Center {
    border-bottom: 1px solid $color-border;

    & > label {
      display: inline-block;
      padding: 0 2px;
      color: $color-label;
      font-size: 12px;
      transform: translateY(-10px);
    }
  }

  &End {
    flex-grow: 1;
    border-top: 1px solid $color-border;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    border-radius: 4px;
    border-radius: 0 4px 4px 0;
  }
}

.message {
  display: block;
  margin-top: 5px;
  color: $color-label;
  font-size: 11px;
  text-align: center;
}

.error {
  display: block;
  margin-top: 5px;
  color: $color-error;
  font-size: 11px;
  text-align: center;
}
