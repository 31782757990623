@import 'styles/variables';

.root {
  position: relative;
  padding: 10px 14px;
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(7, 9, 27, 0.1);

  &:hover {
    box-shadow: 0 3px 10px rgba(7, 9, 27, 0.25);
    cursor: pointer;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg:last-child {
    margin-left: auto;
  }
}

.rating {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  background-color: $color-divider;
  border-radius: 2px;

  p {
    height: 12px;
    margin-left: 1px;
    font-size: 12px;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
}

.discount {
  position: absolute;
  z-index: 2;
  top: 148px;
  padding: 3px 6px;
  background-color: $color-red;
  border-radius: 4px;
  color: $color-white;
  font-size: 12px;
  text-decoration: none;
}

.price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 6px;
  gap: 2px;

  &Current {
    font-size: 16px;
    font-weight: 500;
  }

  &Discounted {
    color: $color-red;
  }

  &Old {
    color: $color-text-secondary;
    font-size: 13px;
    font-weight: 500;
    text-decoration: line-through;
  }
}

.info {
  height: 105px;
}

.title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 6px;
  gap: 2px;
}

.name {
  position: relative;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 500;
  -webkit-line-clamp: 3;
  line-height: 16px;
  overflow-wrap: anywhere;
}

.weight {
  color: $color-text-secondary;
  font-size: 14px;
}

.bottom {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;

  & > button {
    width: 100%;
    border: none;
  }

  & > div {
    display: flex;
    width: 100%;
    background-color: #f8f8f8;

    button {
      width: 30px !important;
      height: 30px !important;
    }

    span {
      width: auto;
      flex: 1;
    }
  }

  & > svg {
    margin-left: 8px;
  }
}

// @media screen and (max-width: 640px) {
//   .root {
//     padding: 8px 12px;
//   }

//   .image {
//     padding: 4px;

//     img {
//       height: 92px;
//     }
//   }

//   .discount {
//     top: 100px;
//   }

//   .info {
//     height: 84px;
//   }

//   .title {
//     max-height: 32px;
//     -webkit-line-clamp: 2;
//   }

//   .priceCurrent {
//     font-size: 18px;
//   }
// }
