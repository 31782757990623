@import 'styles/variables';

.root {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.text {
  flex: 1;
  font-size: 20px;
  font-weight: 500;
}

.content {
  flex: 1;
}

.closeIcon {
  margin-left: 12px;
  cursor: pointer;
  font-size: 18px;
}
