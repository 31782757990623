@import 'styles/variables';

.root {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 2px solid $color-border;
  border-radius: 8px;
  color: $color-placeholder;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  user-select: none;

  svg {
    margin-right: 8px;
  }
}

.selected {
  border: 2px solid $color-primary;
  color: $color-text;

  svg {
    path {
      fill: $color-primary;
    }
  }
}
