@import 'styles/variables';

.root {
  margin-bottom: 16px;
}

.popper {
  z-index: 100;
  padding: 8px 0;
  border-top: none;
}

.options {
  overflow: auto;
  max-height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: $color-text;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 122, 0, 0.25);
  }
}
