@import 'styles/variables';

.root {
  display: grid;
  justify-content: center;
  margin-bottom: 24px;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, 175px);
}

.banner {
  position: relative;
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(7, 9, 27, 0.1);

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 320px;
    margin: 0 auto;
    border-radius: 4px;
    object-fit: cover;
    object-position: top;
  }
}

@media screen and (max-width: 640px) {
  .root {
    margin-bottom: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, 155px);
  }

  .banner img {
    max-height: 250px;
  }
}

@media screen and (max-width: 420px) {
  .root {
    margin-bottom: 12px;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, 150px);
  }
}
