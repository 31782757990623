@import 'styles/variables';

.directionRow {
  display: flex;
  flex-direction: row;

  .radioRoot:not(:last-child) {
    margin-right: 32px;
  }
}

.directionColumn {
  display: flex;
  flex-direction: column;

  .radioRoot:not(:last-child) {
    margin-bottom: 8px;
  }
}

.label {
  display: inline-block;
  margin-bottom: 8px;
  font-family: $font-sans;
  font-size: 14px;
}

.error {
  margin-top: 5px;
  color: $color-error;
  font-size: 11px;
  text-align: center;
}

.radio {
  &Label {
    font-size: 14px;
    line-height: 16px;
  }

  &Checkmark {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    &::after {
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
    }
  }
}
