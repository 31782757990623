@import 'styles/variables';

.trigger {
  display: inline-block;
  color: $color-icon;
  cursor: pointer;

  &:hover {
    color: $color-primary;

    & > svg {
      color: $color-primary;
    }
  }
}

.popper {
  z-index: 55;
  width: 180px;
  padding: 8px 0;
  border-top: 0;
  background-color: #656a81;
  border-radius: 8px;
}
